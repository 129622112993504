import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import Spinner from '../../components/Spinner'
import PageLayout from '../../layout/PageLayout'
import EntityDetailForm from './EntityDetailForm'
import privateAPI from '../../APIs/private'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import AuditLogsModal from '../../components/AuditLogsModal'

const getEntity = async (id: string) => {
  const response = await privateAPI.get(`/queries/entities/${id}`)
  const data = response.data?.rows?.[0]
  // parse notes from string to array
  if (data) {
    data.notes = JSON.parse(data.notes || '[]')
    return data
  }

  return {}
}

const saveEntity = async (id: string, data: any) =>
  await privateAPI.post(`/queries/entities/${id}`, data)

const regenerateTransactionDocuments = async (id: string) =>
  await privateAPI.post(
    `/queries/ENTITIES/${id}/regenerateTransactionDocuments`,
  )

const EntityDetail: React.FC = () => {
  const [showAuditLogModal, setShowAuditLogModal] = useState(false)
  const queryClient = useQueryClient()
  const { id }: any = useParams()
  const methods = useForm()
  const { reset } = methods

  const { isInitialLoading, data } = useQuery({
    queryKey: ['entities', id],
    queryFn: async () => getEntity(id),
    enabled: +id > 0,
  })

  useEffect(() => reset(data), [data, reset])

  const saveEntityMutation = useMutation({
    mutationFn: async (data: any) => {
      if (data.notes && data.notes.length) {
        data.notes.forEach((note: any) => delete note.updated)
      }
      data.notes = JSON.stringify(data.notes)
      await saveEntity(id, data)
    },
    onSuccess: async () => queryClient.invalidateQueries(['entities', id]),
  })

  const handleRegenerateDocumentsMutation = useMutation({
    mutationFn: async () => await regenerateTransactionDocuments(id),
    onSuccess: () => window.alert('Documents regenerated successfully!'),
  })

  const onSubmit = async (data: any) => saveEntityMutation.mutate(data)

  if (isInitialLoading) {
    return (
      <PageLayout>
        <Spinner />
      </PageLayout>
    )
  }

  return (
    <PageLayout>
      <FormProvider {...methods}>
        <EntityDetailForm
          onSubmit={onSubmit}
          btnLoading={
            saveEntityMutation.isLoading ||
            handleRegenerateDocumentsMutation.isLoading
          }
          data={data}
          id={id}
          handleRegenerateDocuments={handleRegenerateDocumentsMutation.mutate}
          setShowAuditLogModal={() => setShowAuditLogModal(true)}
        />
      </FormProvider>
      {showAuditLogModal && (
        <AuditLogsModal
          open={showAuditLogModal}
          abandonAction={() => setShowAuditLogModal(false)}
          type="Entity"
          id={id}
        />
      )}
    </PageLayout>
  )
}

export default EntityDetail
