import React, { useState } from 'react'
import { CButton, CFormInput, CFormLabel } from '@coreui/react-pro'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from '../../features/hooks'
import { RootState } from '../../store'
import { formatDecimal, formatToPT } from '../../utils/formatUtils/utils'
import Select from '../assets/components/Select'
import TabContainer from '../../components/TabContainer'
import { useHistory, useParams } from 'react-router-dom'
import WithdrawModal from '../../components/WithdrawModal'
import TransferFundaModal from '../../components/TransferFundaModal'
import { getCashAccountTransactions, setCashExternalAccount } from '../../features/cashAccountSlice'
import Note from '../../components/Note'
import SubmitButton from '../../components/SubmitButton'
import PaginatedTable from '../../components/PaginatedTable'

type CashExternalAccountRow = {
  amlStatus: string
  bankName: string
  cashAccountId: number
  cashExternalAccountId: number
  createdAt: number
  isActive: boolean
  source: string
}

const CashAccountDetailForm: React.FC<any> = ({ btnLoading, onSubmit, setShowAuditLogModal }: any) => {
  const history = useHistory()
  const { id }: any = useParams()
  const dispatch = useAppDispatch()
  const [active, setActive] = useState<string>('Transactions')
  const [note, setNote] = useState<string>('')
  const [withdrawModal, setWithdrawModal] = useState<boolean>(false)
  const [transferFundsModal, setTransferFundsModal] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const { cashAccount, cashExternalAccount, UPHOLD_ACCESSES_COLUMNS, LINKED_ACCOUNTS_COLUMNS, CASH_TRANSACTION_COLUMNS } = useAppSelector(
    (state: RootState) => state.cashAccount,
  )
  const me = useAppSelector((state: RootState) => state.user)
  const { user } = useAppSelector((state: RootState) => state.users)
  const { register, control, watch, handleSubmit } = useFormContext()

  const { fields, prepend, remove } = useFieldArray({
    control,
    name: 'notes',
  })

  const handleOpenModal = (row: CashExternalAccountRow) => {
    dispatch(setCashExternalAccount(row))
    setWithdrawModal(true)
  }

  const updateNote = () => {
    prepend({
      firstName: me.firstName,
      notes: note,
      time: Date.now(),
      updated: true,
    })
    setNote('')
  }

  const handlePageChange = async (page: number) => {
    setCurrentPage(page)
    await dispatch(getCashAccountTransactions({ id, start: page * 10, current: page }))
  }

  const {
    cashAccountId,
    availableBalance,
    pendingCreditBalance,
    pendingDebitBalance,
    userId,
    linkedAccounts,
    action_CreateLiquidshareTransfer,
    availableWithdrawBalance,
    operatingAccountBalance,
    upholdAccounts,
    cashAccountCashTransactions,
    filteredCount
  } = cashAccount || {}

  const { fullName } = user || {}

  return (
    <>
      {withdrawModal && (
        <WithdrawModal cashExternalAccount={cashExternalAccount} handleClose={() => setWithdrawModal(false)} />
      )}
      {transferFundsModal && (
        <TransferFundaModal cashAccountId={cashAccountId} handleClose={() => setTransferFundsModal(false)} />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex justify-content-between">
          <h3>Cash Account No. {cashAccountId}</h3>
          <div>
            <CButton className='me-2' onClick={setShowAuditLogModal}>View Logs</CButton>
            <SubmitButton loading={btnLoading} text="Save" />
            {action_CreateLiquidshareTransfer && <CButton
              onClick={() =>
                setTransferFundsModal(true)
              }
            >
              Transfer Funds
            </CButton>}
          </div>
        </div>
        <div className="row g-3 mb-4">
          <div className="col-md-3">
            <CFormLabel>Owner</CFormLabel>
            <CFormInput disabled {...register('ownerName')} />
          </div>
          {userId && (
            <div className="col-md-3">
              <CFormLabel>User</CFormLabel>
              <Select
                readOnly
                options={Option}
                watchValue={fullName}
                query="users"
                id={userId}
                ref={null}
              />
            </div>
          )}
          <div className="col-md-3">
            <CFormLabel>VRN Account Number</CFormLabel>
            <CFormInput disabled {...register('accountNumber')} />
          </div>
          <div className="col-md-3">
            <CFormLabel>Available for Purchase Balance</CFormLabel>
            <CFormInput disabled value={formatDecimal(availableBalance || 0)} />
          </div>
          <div className="col-md-3">
            <CFormLabel>Pending Credit Balance</CFormLabel>
            <CFormInput
              disabled
              value={formatDecimal(pendingCreditBalance || 0)}
            />
          </div>
          <div className="col-md-3">
            <CFormLabel>Pending Debit Balance</CFormLabel>
            <CFormInput
              disabled
              value={formatDecimal(pendingDebitBalance || 0)}
            />
          </div>
          <div className="col-md-3">
            <CFormLabel>Created At</CFormLabel>
            <CFormInput
              disabled
              value={formatToPT(watch('createdAt'), 'MM/DD/YY')}
            />
          </div>
          <div className="col-md-3">
            <CFormLabel>Available to Withdraw Balance</CFormLabel>
            <CFormInput
              disabled
              value={formatDecimal(availableWithdrawBalance || 0)}
            />
          </div>
          <div className="col-md-3">
            <CFormLabel>Operating Account Balance</CFormLabel>
            <CFormInput
              disabled
              value={formatDecimal(operatingAccountBalance || 0)}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="col-md-12 d-flex align-items-end justify-content-between">
            <div className="d-flex flex-column w-100 me-2 mt-2">
              <h4>Notes</h4>
              <CFormLabel>Add Note</CFormLabel>
              <CFormInput
                disabled={btnLoading}
                value={note}
                onChange={(e: any) => setNote(e.target.value)}
              />
            </div>
            <CButton disabled={!note} type="button" onClick={updateNote}>
              Add
            </CButton>
          </div>
          {fields && fields.length
            ? fields.map((note: any, i: number) => (
              <Note
                index={note.id}
                key={note.id}
                note={note}
                deleteNote={() => remove(i)}
              />
            ))
            : null}
        </div>
        <br />
        <div className="d-flex justify-content-between">
          <TabContainer
            tabs={['Transactions', 'Linked Accounts', 'Uphold Accounts']}
            active={active}
            setActive={setActive}
          />
          {active === 'Linked Accounts' && (
            <CButton
              onClick={() =>
                history.push(`/bank_accounts/-1?cashAccountId=${cashAccountId}`)
              }
            >
              Add Bank Account
            </CButton>
          )}
        </div>
        <PaginatedTable
          currentPage={currentPage}
          filteredCount={filteredCount}
          handleClick={(item: any) =>
            active === 'Linked Accounts'
              ? history.push(
                `/cash_external_accounts/${item.cashExternalAccountId}`,
              )
              : active === 'Uphold Accounts' ? history.push(`/uphold_accesses/${item.upholdAccessId}`) : null
          }
          columns={active === 'Linked Accounts' ? LINKED_ACCOUNTS_COLUMNS : active === 'Uphold Accounts' ? UPHOLD_ACCESSES_COLUMNS : CASH_TRANSACTION_COLUMNS}
          items={ active === 'Linked Accounts' ? linkedAccounts : active === 'Uphold Accounts' ? upholdAccounts : cashAccountCashTransactions}
          handleButtonClick={handleOpenModal}
          handlePageChange={handlePageChange}
        />
      </form>
    </>
  )
}

export default CashAccountDetailForm
