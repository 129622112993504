import React from 'react'
import { CBadge, CButton, CSmartPagination, CSmartTable } from '@coreui/react-pro'
import {
  capitalizeFirstLetter,
  formatToPT,
  toCamalCase,
  resolveDocumentType
} from '../utils/formatUtils/utils'
import { getBadgeColor } from '../utils/renderUtils/utils'
import moment from 'moment'
import { Link } from 'react-router-dom'

const PaginatedTable: any = ({
  items,
  columns,
  handleClick,
  handleButtonClick,
  isSelectable = false,
  handleCheckboxClick = null,
  filteredCount = 0,
  handlePageChange = () => null,
  currentPage = 0,
}: any) => {
  const scopedColumns: any = {}

  const updatedColumns = columns ? [...columns] : [...items]
  const arr = !!items ? [...items] : []

  const handleBtnClick = (e: any, row: any) => {
    e.stopPropagation()
    handleButtonClick(row)
  }

  const wrapper = (column: any) => {
    const { name, renderer } = column || {}

    const obj = (row: any) => {
      const data = row[name]
      switch (renderer) {
        case 'Number':
        case 'Shares': {
          return (
            <td style={{ textAlign: 'right' }}>
              {data?.toLocaleString('en-US')}
            </td>
          )
        }
        case 'Money': {
          return <td style={{ textAlign: 'right' }}>{data}</td>
        }
        case 'String': {
          return <td>{data}</td>
        }
        case 'Series': {
          return <td>{data?.replace('Linqto Liquidshares LLC -', '')}</td>
        }
        case 'Boolean': {
          return <td>{capitalizeFirstLetter(data?.toString() || '')}</td>
        }
        case 'Link': {
          const { documentId } = row || {}
          return <td><Link to={`/documents/${documentId}`}>{data}</Link> </td>
        }
        case 'Button': {
          if (data) {
            const { hasCashAccount, canWithdraw, fileUrl, associate } = row || {}
            if (canWithdraw) {
              return (
                <td>
                  <CButton onClick={(e: any) => handleBtnClick(e, row)}>
                    Withdraw
                  </CButton>
                </td>
              )
            } else if (fileUrl) {
              return (
                <td>
                  <CButton onClick={() => window.open(data)}>
                    View File
                  </CButton>
                </td>
              )
            } else if (associate) {
              return (
                <td>
                  <CButton onClick={(e) => handleBtnClick(e, row)}>
                    Associate
                  </CButton>
                </td>
              )
            } else {
              return (
                <td>
                  <CButton onClick={(e: any) => handleBtnClick(e, row)}>
                    {`Create ${hasCashAccount ? 'VRN' : 'Cash Account'}`}
                  </CButton>
                </td>
              )
            }
          } else {
            return <td></td>
          }
        }
        case 'Notes': {
          if (data && data.length) {
            return (
              <td>
                {data.map((note: any, i: number) => {
                  return (
                    <span key={i}>
                      <b>{note.firstName}</b> {note.notes}{' '}
                      {note.date || moment(note.time).format('MMM DD')} <br />
                    </span>
                  )
                })}
              </td>
            )
          }
          return <td></td>
        }
        case 'Status': {
          return (
            <td>
              <CBadge
                style={{
                  backgroundColor: getBadgeColor(toCamalCase(data))[0],
                  color: getBadgeColor(toCamalCase(data))[1],
                }}
              >
                {toCamalCase(data)}
              </CBadge>
            </td>
          )
        }
        case 'Date':
        case 'DateTime': {
          return <td>{formatToPT(data, 'MM/DD/YY hh:mm:ss a')}</td>
        }
        case 'FixedWidthJsonData': {
          return <td style={{ maxWidth: '25%' }}>
            {data?.replace(/,/g, " , ").replace(/:/g, " : ")}</td>
        }
        case 'DocumentType': {
          return <td>{resolveDocumentType(data)}</td>
        }
        default:
          return <td>{data}</td>
      }
    }
    return obj
  }

  updatedColumns.forEach((t: any) => {
    if (!!t) {
      scopedColumns[t?.name] = wrapper(t)
    }
  })

  return (
    <div className="hover">
      <CSmartTable
        tableBodyProps={{ className: 'hover' }}
        clickableRows
        selectable={isSelectable}
        items={arr}
        columns={updatedColumns}
        onRowClick={(item: any) => (handleClick ? handleClick(item) : null)}
        pagination
        tableProps={{
          hover: true,
          borderColor: 'primary',
          color: 'white',
          striped: true,
          align: 'bottom',
          responsive: true,
        }}
        scopedColumns={scopedColumns}
        onSelectedItemsChange={(items) => handleCheckboxClick(items)}
      />
    <div className='d-flex align-items-center'>
      <CSmartPagination
        className="hover"
        activePage={currentPage + 1}
        pages={Math.ceil(filteredCount / 10)}
        onActivePageChange={(item: any) =>
          handlePageChange && item - 1 !== -1
            ? handlePageChange(item - 1)
            : null
        }
      />
      {filteredCount > 0 && (
        <div className="ms-4 pagination entries">
          {filteredCount > 0 && (
            <div className="pagination entries">
              {currentPage === 0 ? currentPage + 1 : (currentPage * 10) + 1} - {' '}
              {(filteredCount <= currentPage + 10) || (currentPage + 1) * 10 >= filteredCount ? filteredCount : currentPage === 0 ? currentPage + 10 : (currentPage + 1) * 10} of {filteredCount}
            </div>
          )}</div>)}
      </div>
    </div>
  )
}

export default PaginatedTable
