import React, { useState } from 'react'
import { CButton } from '@coreui/react-pro'
import privateAPI from '../../../APIs/private'
import GenericTable from '../../../components/GenericTable'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import Loading from './Loading'
import { useHistory } from 'react-router-dom'
import FilterDocumentsModal from './FilterDocumentsModal'
import CIcon from '@coreui/icons-react'
import { cilFilter } from '@coreui/icons'


const DOCUMENT_COLUMNS = [
  {
    key: 'name',
    name: 'name',
    label: "File Name",
    type: 'Link',
    renderer: 'Link'
  },
  {
    key: 'type',
    name: 'type',
    label: "File Type",
    type: 'String',
    renderer: 'DocumentType'
  },
  {
    key: 'fileUrl',
    name: 'fileUrl',
    label: "Document Actions",
    type: 'Button',
    renderer: 'Button'
  },
  {
    key: 'createdAt',
    name: 'createdAt',
    label: "Created At",
    type: 'Date',
    renderer: 'DateTime'
  },
  {
    key: 'updatedAt',
    name: 'updatedAt',
    label: "Updated At",
    type: 'Date',
    renderer: 'DateTime'
  },
]

interface UserDocumentsProps {
  id: string,
  selectedItems: any[],
  setSelectedItems: (value: any) => any,
  setShowRecategorizeDocumentsModal: (value: any) => any,
  setShowUploadDocumentModal: (value: any) => any,
  documentFilters: string[],
  setDocumentFilters: (value: any) => any
}

const getUserDocuments = async (payload: any) => {
  const response = await privateAPI.post('/queries/documents', payload)
  return response.data
}


const UserDocuments: React.FC<UserDocumentsProps> = ({
  id,
  selectedItems,
  setSelectedItems,
  setShowRecategorizeDocumentsModal,
  setShowUploadDocumentModal,
  documentFilters,
  setDocumentFilters,
}: UserDocumentsProps) => {
  const queryClient = useQueryClient()
  const [showfilterDocumentsModal, setShowFilterDocumentsModal] = useState(false)

  const DOCUMENT_DATA = {
    documentId: {},
    companyId: {},
    orderId: {},
    userId: { filterValues: [id] },
    entityId: {},
    originationId: {},
    riafirmId: {},
    cashExternalAccountId: {},
    upholdAccessId: {},
    segmentId: {},
    name: {},
    type: {
      filterValues: [...documentFilters]
    },
    fileUrl: {},
    createdAt: {
      "sortAsc": false,
      "sortIndex": 1
    },
    updatedAt: {}
  }


  const history = useHistory()
  const { data, isLoading } = useQuery({
    queryKey: ['user_documents', id],
    queryFn: async () => await getUserDocuments(DOCUMENT_DATA)
  })

  const handleSubmit = useMutation({
    mutationFn: async () => {
      const res = await getUserDocuments(DOCUMENT_DATA)
      setShowFilterDocumentsModal(false)
      queryClient.setQueryData(['user_documents', id], res)
    }
  })

  if (isLoading || handleSubmit.isLoading) return <Loading />
  return (
    <div className="mt-4">
      <div className="d-flex align-items-start mt-2">
        <CButton className="text-nowrap ms-2"
          disabled={selectedItems?.filter((a) => a._selected === true).length === 0} onClick={() => setShowRecategorizeDocumentsModal(true)}>
          Bulk Recategorize
        </CButton>
        <CButton className="text-nowrap ms-2"
          onClick={() => setShowUploadDocumentModal(true)}>
          Upload Document
        </CButton>
        <CButton className="text-nowrap ms-2" onClick={() => setShowFilterDocumentsModal(true)}>
          <CIcon icon={cilFilter} />
        </CButton>
      </div>
      <div>
        <GenericTable
          items={data.rows}
          columns={DOCUMENT_COLUMNS}
          isSelectable={true}
          handleCheckboxClick={setSelectedItems}
          handleViewDocument={() => history.push('/documents')}
        />
      </div>
      {!!showfilterDocumentsModal && (
        <FilterDocumentsModal
          open={showfilterDocumentsModal}
          abandonAction={() => setShowFilterDocumentsModal(false)}
          documentFilters={documentFilters}
          setDocumentFilters={setDocumentFilters}
          getUserDocuments={handleSubmit.mutate}
        />
      )}
    </div>
  )
}

export default UserDocuments
