import React, { useEffect, useState } from 'react'
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CButton,
  CFormLabel,
  CFormInput,
  CCarousel,
  CCarouselItem,
  CImage,
} from '@coreui/react-pro'
import { Controller, useForm } from 'react-hook-form'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
import { useAppDispatch, useAppSelector } from '../../../features/hooks'
import { RootState } from '../../../store'
import {
  getManualKyc,
  resetError,
  saveManualKyc,
} from '../../../features/usersSlice'
import { useParams } from 'react-router'
import Spinner from '../../../components/Spinner'
import CIcon from '@coreui/icons-react'
import { cilCropRotate } from '@coreui/icons'

interface VerifyModalProps {
  setOpen: any
}

const VerifyModal: React.FC<VerifyModalProps> = ({ setOpen }: any) => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState<boolean>(false)
  const [images, setImages] = useState<any[]>([])
  const [angle, setAngle] = useState<number>(0)
  const params: { id: string | undefined } = useParams()
  const { manualKycUser, error, errorMessage, user } = useAppSelector(
    (state: RootState) => state.users,
  )
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
    watch,
    getValues,
  } = useForm()

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      await dispatch(getManualKyc({ id: Number(params?.id || 0) }))
      setLoading(false)
    }
    fetchData()
  }, [dispatch, params?.id])

  useEffect(() => {
    setImages([
      ...[
        ...(manualKycUser.idFrontUrl !== undefined
          ? [manualKycUser.idFrontUrl]
          : []),
        ...(manualKycUser.idBackUrl !== undefined
          ? [manualKycUser.idBackUrl]
          : []),
        ...(manualKycUser.selfieUrl !== undefined
          ? [manualKycUser.selfieUrl]
          : []),
      ],
    ])
  }, [manualKycUser])

  useEffect(() => reset(manualKycUser), [reset, manualKycUser])

  const openPlaidUserLink = async () => window.open(`https://dashboard.plaid.com/identity_verification/sessions/${user?.plaidSessionId}`, '_blank', 'noopener, noreferrer');

  const { birthDay, birthMonth, birthYear, gender } = manualKycUser || {}

  const onSubmit = async (data: any) => {
    setLoading(true)
    const address = { ...data.address }
    await dispatch(
      saveManualKyc({
        id: params?.id,
        data: {
          address,
          birthDay: data.birthDay,
          birthMonth: data.birthMonth,
          birthYear: data.birthYear,
          legalName: data.legalName,
          gender: data.gender,
          idType: data.idType,
          selfieSimilarity: 0,
        },
      }),
    )
    setLoading(false)
    window.location.reload()
  }

  const handleMarkAsFail = async () => {
    const data = getValues()
    setLoading(true)
    await dispatch(
      saveManualKyc({
        id: params?.id,
        data: {
          ...data,
          selfieSimilarity: 0,
          kycStatus: 'FAILED',
        },
      }),
    )
    window.location.reload()
  }

  const handleErrorClose = () => {
    setOpen(false)
    dispatch(resetError())
  }

  if (loading) {
    return (
      <CModal visible alignment="center" size="lg">
        <Spinner />
      </CModal>
    )
  }

  if (error && errorMessage) {
    return (
      <CModal visible alignment="center" onClose={handleErrorClose}>
        <CModalHeader>Error</CModalHeader>
        <CModalBody>
          <div className="row g-3 mb-4">
            {errorMessage && (
              <span className="text-danger">{errorMessage}</span>
            )}
          </div>
        </CModalBody>
      </CModal>
    )
  }

  return (
    <CModal visible alignment="center" size="lg" onClose={() => setOpen(false)}>
      <CModalHeader>
        <CModalTitle>Verify Identity</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CCarousel controls interval={false}>
          {images && images.length
            ? images.map((image, i) => (
              <CCarouselItem key={i} style={{ height: '600px' }}>
                <CImage
                  style={{
                    height: '600px',
                    width: 'inherit',
                    transform: `rotate(${angle}deg)`,
                  }}
                  className="d-block hover"
                  src={image}
                  alt={`Display ${i}`}
                  onClick={() => window.open(image)}
                />
              </CCarouselItem>
            ))
            : null}
        </CCarousel>
        <div className="d-flex align-items-center justify-content-center mt-4 mb-4 hover">
          <CIcon
            height={30}
            width={30}
            icon={cilCropRotate}
            onClick={() =>
              setAngle((angle: number) =>
                angle === 360 ? -angle + 450 : angle + 90,
              )
            }
          />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row g-3 mb-2">
            <div className="col-md-4">
              <CFormLabel>Birth Day</CFormLabel>
              <CFormInput
                defaultValue={birthDay}
                {...register('birthDay', {
                  required: 'This field is required',
                })}
              />
              {errors.birthDay && (
                <span className="text-danger">{errors.birthDay.message}</span>
              )}
            </div>
            <div className="col-md-4">
              <CFormLabel>Birth Month</CFormLabel>
              <CFormInput
                defaultValue={birthMonth}
                {...register('birthMonth', {
                  required: 'This field is required',
                })}
              />
              {errors.birthMonth && (
                <span className="text-danger">{errors.birthMonth.message}</span>
              )}
            </div>
            <div className="col-md-4">
              <CFormLabel>Birth Year</CFormLabel>
              <CFormInput
                defaultValue={birthYear}
                {...register('birthYear', {
                  required: 'This field is required',
                })}
              />
              {errors.birthYear && (
                <span className="text-danger">{errors.birthYear.message}</span>
              )}
            </div>
            <div className="row g-3 mb-2">
              <div className="col-md-4">
                <CFormLabel>Legal Name</CFormLabel>
                <CFormInput
                  {...register('legalName', {
                    required: 'This field is required',
                  })}
                />
                {errors.legalName && (
                  <span className="text-danger">
                    {errors.legalName.message}
                  </span>
                )}
              </div>
              <div className="col-md-4">
                <CFormLabel>Country</CFormLabel>
                <Controller
                  rules={{ required: 'This field is required.' }}
                  control={control}
                  name="address.country"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <CountryDropdown
                      name="address.country"
                      classes="form-select"
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
                {errors.address && (
                  <span className="text-danger">
                    {errors?.address?.country?.message}
                  </span>
                )}
              </div>
              <div className="col-md-4">
                <CFormLabel>Street 1</CFormLabel>
                <CFormInput {...register('address.street1')} />
              </div>
            </div>
            <div className="row g-3 mb-2">
              <div className="col-md-4">
                <CFormLabel>Street 2</CFormLabel>
                <CFormInput {...register('address.street2')} />
              </div>
              <div className="col-md-4">
                <CFormLabel>City</CFormLabel>
                <CFormInput {...register('address.city')} />
              </div>
              <div className="col-md-4">
                <CFormLabel>State</CFormLabel>
                <Controller
                  control={control}
                  name="address.state"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <RegionDropdown
                      classes="form-select"
                      country={watch('address.country')}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </div>
            </div>
            <div className="row g-3 mb-2">
              <div className="col-md-4">
                <CFormLabel>Zip</CFormLabel>
                <CFormInput {...register('address.zip')} />
              </div>
              <div className="col-md-4">
                <CFormLabel>Gender</CFormLabel>
                <select
                  className="form-select"
                  defaultValue={gender}
                  {...register('gender')}
                >
                  <option value="UNKNOWN">Unknown</option>
                  <option value="MALE">Male</option>
                  <option value="FEMALE">Female</option>
                </select>
              </div>
              <div className="col-md-4 d-flex">
                <div className="form-check me-2">
                  <label className="form-check-label" htmlFor="field-rain">
                    <input
                      className="form-check-input me-2"
                      {...register('idType')}
                      type="radio"
                      name="idType"
                      value="DRIVERS_LICENSE"
                      id="field-rain"
                    />
                    Driver&apos;s License
                  </label>
                </div>
                <div className="form-check me-2">
                  <label className="form-check-label" htmlFor="field-rain">
                    <input
                      className="form-check-input"
                      {...register('idType')}
                      type="radio"
                      name="idType"
                      value="PASSPORT"
                      id="field-rain"
                    />
                    Passport
                  </label>
                </div>
                <div className="form-check me-2">
                  <label className="form-check-label" htmlFor="field-rain">
                    <input
                      className="form-check-input"
                      {...register('idType')}
                      type="radio"
                      name="idType"
                      value="OTHER"
                      id="field-rain"
                    />
                    Other
                  </label>
                </div>
              </div>
            </div>
          </div>
            <CModalFooter className="d-flex justify-content-between">
            <CButton color="danger" onClick={handleMarkAsFail}>
              Mark as Fail
            </CButton>
            <div>
              {user?.plaidSessionId && (
                <CButton className="me-2" onClick={openPlaidUserLink}>
                  View Plaid Session
                </CButton>
              )}
              <CButton color="primary" type="submit">
                Verify Identity
              </CButton>
            </div>
          </CModalFooter>
        </form>
      </CModalBody>
    </CModal>
  )
}

export default VerifyModal
