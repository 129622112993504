import React, { useState } from 'react'
import {
  CLoadingButton,
  CTable,
  CTableHead,
  CTableBody,
  CTableRow,
  CTableHeaderCell,
  CTableDataCell

} from '@coreui/react-pro'
import { RootState } from '../../../store'
import { useAppDispatch, useAppSelector } from '../../../features/hooks'
import { getUser, saveUser } from '../../../features/usersSlice'

const UserFeatures: React.FC = () => {
  const [btnLoading, setBtnLoading] = useState('')
  const dispatch = useAppDispatch()
  const { user } = useAppSelector((state: RootState) => state.users)

  const {
    plaidLinkingAllowed,
    isBlacklisted,
    cashWithdrawalEnabled,
    achFundingEnabled,
    instantAchFundingEnabled,
    plaidFraudDetectionEnabled
  } = user


  const updateFeature = async (feature: string) => {
    setBtnLoading(feature)
    const obj = { ...user }
    obj[feature] = !user[feature]
    await dispatch(saveUser({ id: user.userId, data: obj }))
    await dispatch(getUser({ id: user.userId }))
    setBtnLoading('')
  }
  return (
    <>
      <CTable striped hover borderColor="primary" className="border-bottom">
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell>Feature</CTableHeaderCell>
            <CTableHeaderCell>Enabled</CTableHeaderCell>
            <CTableHeaderCell></CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          <CTableRow>
            <CTableDataCell valign='middle' >Blacklisted</CTableDataCell>
            <CTableDataCell valign='middle'>{isBlacklisted ? 'Yes' : 'No'}</CTableDataCell>
            <CTableDataCell valign='middle' style={{ minWidth: '200px' }}>
              <CLoadingButton onClick={() => updateFeature('isBlacklisted')}
                loading={btnLoading === 'isBlacklisted'}
                disabled={btnLoading === 'isBlacklisted'}>{isBlacklisted ? 'Disable' : 'Enable'}
              </CLoadingButton>
            </CTableDataCell>
          </CTableRow>
          <CTableRow>
            <CTableDataCell valign='middle' >Cash Withdrawal Enabled</CTableDataCell>
            <CTableDataCell valign='middle'>{cashWithdrawalEnabled ? 'Yes' : 'No'}</CTableDataCell>
            <CTableDataCell valign='middle' style={{ minWidth: '200px' }}>
              <CLoadingButton onClick={() => updateFeature('cashWithdrawalEnabled')}
                loading={btnLoading === 'cashWithdrawalEnabled'}
                disabled={btnLoading === 'cashWithdrawalEnabled'}>{cashWithdrawalEnabled ? 'Disable' : 'Enable'}
              </CLoadingButton>
            </CTableDataCell>
          </CTableRow>
          <CTableRow>
            <CTableDataCell valign='middle' >Plaid Linking Allowed</CTableDataCell>
            <CTableDataCell valign='middle'>{plaidLinkingAllowed ? 'Yes' : 'No'}</CTableDataCell>
            <CTableDataCell valign='middle' style={{ minWidth: '200px' }}>
              <CLoadingButton onClick={() => updateFeature('plaidLinkingAllowed')}
                loading={btnLoading === 'plaidLinkingAllowed'}
                disabled={btnLoading === 'plaidLinkingAllowed'}>{plaidLinkingAllowed ? 'Disable' : 'Enable'}
              </CLoadingButton>
            </CTableDataCell>
          </CTableRow>
          <CTableRow>
            <CTableDataCell valign='middle' >ACH Funding Enabled</CTableDataCell>
            <CTableDataCell valign='middle'>{achFundingEnabled ? 'Yes' : 'No'}</CTableDataCell>
            <CTableDataCell valign='middle' style={{ minWidth: '200px' }}>
              <CLoadingButton onClick={() => updateFeature('achFundingEnabled')}
                loading={btnLoading === 'achFundingEnabled'}
                disabled={btnLoading === 'achFundingEnabled'}>{achFundingEnabled ? 'Disable' : 'Enable'}
              </CLoadingButton>
            </CTableDataCell>
          </CTableRow>
          <CTableRow>
            <CTableDataCell valign='middle' >Instant ACH Funding Enabled</CTableDataCell>
            <CTableDataCell valign='middle'>{instantAchFundingEnabled ? 'Yes' : 'No'}</CTableDataCell>
            <CTableDataCell valign='middle' style={{ minWidth: '200px' }}>
              <CLoadingButton onClick={() => updateFeature('instantAchFundingEnabled')}
                loading={btnLoading === 'instantAchFundingEnabled'}
                disabled={btnLoading === 'instantAchFundingEnabled'}>{instantAchFundingEnabled ? 'Disable' : 'Enable'}
              </CLoadingButton>
            </CTableDataCell>
          </CTableRow>
          <CTableRow>
            <CTableDataCell valign='middle' >Plaid Fraud Detection Enabled</CTableDataCell>
            <CTableDataCell valign='middle'>{plaidFraudDetectionEnabled ? 'Yes' : 'No'}</CTableDataCell>
            <CTableDataCell valign='middle' style={{ minWidth: '200px' }}>
              <CLoadingButton onClick={() => updateFeature('plaidFraudDetectionEnabled')}
                loading={btnLoading === 'plaidFraudDetectionEnabled'}
                disabled={btnLoading === 'plaidFraudDetectionEnabled'}>{plaidFraudDetectionEnabled ? 'Disable' : 'Enable'}
              </CLoadingButton>
            </CTableDataCell>
          </CTableRow>
        </CTableBody>
      </CTable>
      <br /> <br />
    </>
  )
}

export default UserFeatures
