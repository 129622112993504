import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import privateAPI from '../APIs/private'
import { customOrder, formatDateTime, formatDecimal, formatTableRenderers, formatToPT } from '../utils/formatUtils/utils'
import { globalErrorHandler } from './commonSlice'
import moment from 'moment'

interface CashAccountState {
  rows: any[]
  total: null
  columns: any[]
  showColumn: any[]
  originalColumns: any[]
  reports: any[]
  cashAccount: any
  cashExternalAccount: any
  UPHOLD_ACCESSES_COLUMNS: any[]
  LINKED_ACCOUNTS_COLUMNS: any[]
  CASH_TRANSACTION_COLUMNS: any[]
}

const initialState: CashAccountState = {
  rows: [],
  total: null,
  columns: [],
  showColumn: [],
  originalColumns: [],
  reports: [],
  cashAccount: {},
  cashExternalAccount: {},
  UPHOLD_ACCESSES_COLUMNS: [],
  LINKED_ACCOUNTS_COLUMNS: [],
  CASH_TRANSACTION_COLUMNS: [],
}

export const getCashAccount = createAsyncThunk(
  'getCashAccount',
  async ({ id }: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await privateAPI.get(`/queries/cash_accounts/${id}`)
      return response.data
    } catch (e) {
      dispatch(globalErrorHandler())
      return rejectWithValue(e)
    }
  },
)

export const getCashAccountTransactions = createAsyncThunk(
  'getCashAccountTransactions',
  async ({ id, start, current }: any, { rejectWithValue, dispatch }) => {
    const data = {
      amount: {},
      availableAt: {},
      balanceType: {},
      cashAccountId: { filterValues: [+id], include: false },
      createdAt: { sortAsc: false, sortIndex: 2 },
      returnReason: {},
      description: {},
      withdrawAvailableAt: {},
      current,
      length: 10,
      start,
    }
    try {
      const response = await privateAPI.post(`/queries/cash_entries`, data)
      return response.data
    } catch (e) {
      dispatch(globalErrorHandler())
      return rejectWithValue(e)
    }
  },
)

export const getUpholdAccounts = createAsyncThunk(
  'getUpholdAccounts',
  async ({ id }: any, { rejectWithValue, dispatch }) => {
    const obj = {
      cashAccountId: { filterValues: [+id], include: false },
      upholdAccessId : {},
      legalName : {},
      amlStatus : {},
      isActive : {},
      deactivatedReason : {},
      deactivatedAt : {},
      identityVerified : {},
      balanceUsd : {},
      notes : {},
      createdAt : {}
    }
    try {
      const response = await privateAPI.post(`/queries/uphold_accesses`, obj)
      return response.data
    } catch (e) {
      dispatch(globalErrorHandler())
      return rejectWithValue(e)
    }
  },
)

export const saveCashAccount = createAsyncThunk(
  'saveCashAccount',
  async ({ id, notes }: any, { rejectWithValue }) => {
    try {
      await privateAPI.post(`/queries/cash_accounts/${id}`, { notes })
      return 200
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const initiateWithdraw = createAsyncThunk(
  'initiateWithdraw',
  async ({ id, data }: any, { rejectWithValue }) => {
    try {
      await privateAPI.post(`/cash_external_accounts/${id}/withdraw`, data)
      return 200
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const transferFunds = createAsyncThunk(
  'transferFunds',
  async ({id, data }: any, { rejectWithValue }) => {
    try {
      await privateAPI.post(`/cashAccount/${id}/transferUsingLiquidshares`,  data)
      return 200
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const getCashAccountLinkedAccounts = createAsyncThunk(
  'getCashAccountLinkedAccounts',
  async ({ id }: any) => {
    const data = {
      source: {},
      createdAt: {},
      bankName: {},
      accountNumberSafe: {},
      amlStatus: {},
      cashAccountId: { filterValues: [+id] },
      cashExternalAccountId: {},
      isActive: { sortIndex: 1, sortAsc: false },
      achDepositAllowed: {},
      achWithdrawAllowed: {},
      action_CreateWithdraw: {},
      wireWithdrawAllowed: {},
    }
    try {
      const response = await privateAPI.post(
        '/queries/cash_external_accounts',
        data,
      )
      return response.data
    } catch (e) {
      console.log('error getting orders: ', e)
    }
  },
)

export const cashAccountSlice = createSlice({
  name: 'cashAccount',
  initialState,
  reducers: {
    resetCashAccount: (state) => {
      state.cashAccount = null
    },
    resetCashExternalAccount: (state) => {
      state.cashExternalAccount = null
    },
    setCashExternalAccount: (state, { payload }) => {
      state.cashExternalAccount = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCashAccount.fulfilled, (state, { payload }: any) => {
      const obj = payload?.rows[0] || {}
      const cashTransactions = obj?.cashTransactions || []
      cashTransactions?.sort((a: any, b: any) => b.date - a.date)
      obj.notes= obj?.notes ? JSON.parse(obj?.notes) : []
      state.cashAccount = obj
      cashTransactions?.forEach(
        (transaction: any) =>
          (transaction.amount = formatDecimal(transaction.amount)),
      )
    })
    builder.addCase(
      getCashAccountLinkedAccounts.fulfilled,
      (state, { payload }: any) => {
        const { rows, columns } = payload || {}
        if (rows && rows.length) {
          rows.forEach((row: any) => {
            if (
              row?.amlStatus !== 'REJECTED' &&
              row.isActive &&
              (row.achWithdrawAllowed || row.wireWithdrawAllowed)
            ) {
              row.canWithdraw = true
            }
          })
          const cols = columns?.map((col: any) => {
            col.key = col.name
            if (col?.type === 'Boolean') {
              col.renderer = 'Boolean'
            }
            return col
          })
          state.LINKED_ACCOUNTS_COLUMNS = cols
          state.cashAccount.linkedAccounts = rows || []
        } else {
          state.cashAccount.linkedAccounts = []
        }
      },
    )
    builder.addCase(getUpholdAccounts.fulfilled, (state, { payload }) => {
      const { rows, columns } = payload || {}
      if (rows && rows.length) {
        const arr = rows?.map((row: any) => {
          const data = { ...row }
          if (row?.notes) {
            data.notes = JSON.parse(row?.notes || [])
          }
          return data
        })
        state.cashAccount.upholdAccounts = arr || []
        const cols = columns?.map((col: any) => {
          col.key = col.name
          if (col.key === 'notes') {
            col.renderer = 'Notes'
          }
          if (col.name === 'isActive' || col.name === 'identityVerified') {
            col.renderer = 'Boolean'
          }
          return col
        })
        state.UPHOLD_ACCESSES_COLUMNS = cols
      } else {
        state.cashAccount.upholdAccounts = []
      }
    })
    builder.addCase(getCashAccountTransactions.fulfilled, (state, { payload }) => {
      const { rows = [], columns = [] } = payload || {}
      // rename column labels
      columns.forEach((col: any) => {
        col.key = col.name
        if (col?.name === 'availableAt') {
          col.label = 'Settled At'
        }
        if (col?.name === 'description') {
          col.label = 'Transaction Type'
        }
        if (col?.name === 'createdAt') {
          col.label = 'Date'
        }
        return col
      })

      rows.forEach((row: any) => {
        if (row?.availableAt) {
          row.createdAt = formatToPT(row.createdAt, 'MM/DD/YY')
          row.availableAt = formatToPT(row.availableAt, 'MM/DD/YY hh:mm:ss a')
          row.withdrawAvailableAt = formatToPT(row.withdrawAvailableAt, 'MM/DD/YY hh:mm:ss a')
        }
      })

      const { rows: dataRows } = formatTableRenderers(rows, columns, false)

      state.cashAccount.cashAccountCashTransactions = dataRows
      state.cashAccount.filteredCount = payload?.filtered?.count || 0
      state.CASH_TRANSACTION_COLUMNS = customOrder(['createdAt', 'amount', 'availableAt', 'description', 'balanceType', 'availableAt', 'withdrawAvailableAt', 'returnReason'], columns)
    })
  },
})

export const {
  resetCashAccount,
  resetCashExternalAccount,
  setCashExternalAccount,
} = cashAccountSlice.actions

export default cashAccountSlice.reducer
