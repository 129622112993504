import React, { useState } from 'react'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import { RootState } from '../../store'
import Spinner from '../../components/Spinner'
import PageLayout from '../../layout/PageLayout'
import { useAppDispatch, useAppSelector } from '../../features/hooks'
import {
  getCashAccount,
  getCashAccountLinkedAccounts,
  getCashAccountTransactions,
  getUpholdAccounts,
  resetCashAccount,
  saveCashAccount,
} from '../../features/cashAccountSlice'
import CashAccountDetailForm from './CashAccountDetailForm'
import { getUser, resetUser } from '../../features/usersSlice'
import AuditLogsModal from '../../components/AuditLogsModal'

const CashAccountDetail: React.FC = () => {
  const [showAuditLogModal, setShowAuditLogModal] = useState(false)
  const { id }: any = useParams()
  const [loading, setLoading] = useState(true)
  const [btnLoading, setBtnLoading] = useState<boolean>(false)

  const dispatch = useAppDispatch()
  const { cashAccount } = useAppSelector(
    (state: RootState) => state.cashAccount,
  )
  const methods = useForm()

  const { reset } = methods

  const onSubmit = async (data: any) => {
    setBtnLoading(true)
    if (data.notes && data.notes.length) {
      data.notes.forEach((note: any) => delete note.updated)
    }
    await dispatch(saveCashAccount({ id, notes: JSON.stringify(data.notes) }))
    await dispatch(getCashAccount({ id }))
    await dispatch(getCashAccountTransactions({ id, start: 0, current: 0 }))
    setBtnLoading(false)
  }

  useEffect(() => {
    const getCashAccountById = async () => {
      setLoading(true)
      const res = await dispatch(getCashAccount({ id }))
      await dispatch(getCashAccountTransactions({ id, start: 0, current: 0 }))
      if (res && res.payload) {
        const { userId, cashAccountId } = res?.payload?.rows?.[0] || {}
        if (userId) {
          await dispatch(getUser({ id: userId }))
        }
        if (cashAccountId) {
          await dispatch(
            getCashAccountLinkedAccounts({
              id: cashAccountId,
            }),
          )
          await dispatch(getUpholdAccounts({ id }))
        }
      }
      setLoading(false)
    }
    getCashAccountById()
    return () => {
      dispatch(resetCashAccount())
      dispatch(resetUser())
    }
  }, [id, dispatch])

  useEffect(() => reset(cashAccount), [reset, cashAccount])

  if (loading) {
    return (
      <PageLayout>
        <Spinner />
      </PageLayout>
    )
  }

  return (
    <FormProvider {...methods}>
      <PageLayout>
        <CashAccountDetailForm btnLoading={btnLoading} onSubmit={onSubmit} setShowAuditLogModal={() => setShowAuditLogModal(true)} />
      </PageLayout>
      {showAuditLogModal && (
        <AuditLogsModal
          open={showAuditLogModal}
          abandonAction={() => setShowAuditLogModal(false)}
          type="CashAccount"
          id={id}
        />
      )}
    </FormProvider>
  )
}

export default CashAccountDetail
